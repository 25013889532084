import {AccountData} from './AccountData';
import {ConsoleDebug} from '../../../_utils/ConsoleDebug';
import {Environment} from "../../../_utils/Environment";
import {Checkout} from "../../../paywall/checkout/views/Checkout";

const CONTEXT_KEY_EVENT_ACCOUNT_DATA_CHANGED = 'event:account_data_changed';
const CTXT_KEY_EVENT_PIANO_JWT_PUSHED = 'event:account_piano_jwt_pushed';
const CTXT_KEY_EVENT_REGISTER_SUCCESS = 'event:account_register_successfully';
const CTXT_KEY_PIANO_INITIALIZED = 'piano:initialized';
const CTXT_KEY_PIANO_PAYWALL_OFFER_SHOWN = 'piano:paywall_offer_shown';
const COOKIE_NAME_PIANO_PARAMS = '__pianoParams';
const TWELVE_HOURS_IN_MS = 12 * 60 * 60 * 1000; // 6 hours in ms
const LOCAL_STORAGE_KEY_LAST_ACCESS_CHECK = "last_piano_access_check";
const PIANO_MM_PLUS_RESOURCE_ID = 'RMBGB9X';
const PIANO_FN_PLUS_RESOURCE_ID = 'REEORT3';
const PIANO_SZ_PLUS_RESOURCE_ID = 'RG2DYH6';
const PIANO_BA_PLUS_RESOURCE_ID = 'RT1U744';
const SELECTOR_PAYWALL = '#piano-static-paywall';

export class PianoHelper {

  constructor(context) {

    this.logInstance = new ConsoleDebug('debug-piano', 'PianoHelper');
    this.logInstance.log('core/account/service/PianoHelper.js constructor');

    // "PianoHelper" ist keine "View", this.context wird aber benötigt
    this.context = context;
    this.env = new Environment();

    this.pianoConfigObj = this.getPianoConfigObj(this.env.getHostnameWithoutSubdomain());
    this.skipAccessCheck = false;

    // when event registration successfully triggered don't check access
    this.context.on(CTXT_KEY_EVENT_REGISTER_SUCCESS, (event) => {
      this.logInstance.log('Auf Event \'' + CTXT_KEY_EVENT_REGISTER_SUCCESS + '\' reagiert', event);
      this.skipAccessCheck = true;
    });

    this.accountData = new AccountData(this.context);
    this.handleAuthSync = this.handleAuthSync.bind(this);
    this.handleExperienceLogic = this.handleExperienceLogic.bind(this);
    this.provideJwt = this.provideJwt.bind(this);
    this.experienceExecute = this.experienceExecute.bind(this);
    this.restorePianoParams = this.restorePianoParams.bind(this);
    this.startCheckout = this.startCheckout.bind(this);
    this.updateCustomFieldAccessExpiration = this.updateCustomFieldAccessExpiration.bind(this);
    this.isAccessCheckNeeded = this.isAccessCheckNeeded.bind(this);
    this.sendCustomFieldUpdate = this.sendCustomFieldUpdate.bind(this);
    this.getPianoConfigObj = this.getPianoConfigObj.bind(this);
  }

  /**
   * Flowchart for AuthSync "init" and "loginRequired" processes
   * @link https://haasmediengruppe.sharepoint.com/:u:/r/sites/HAAS-Webentwicklung/Freigegebene%20Dokumente/General/Tickets/WEB-280%20Piano%20ID%20Auth%20Sync/WEB-280%20Piano%20ID%20Auth%20Sync.vsdx?d=we1b74128a1cd46f0af2f74fcabbc588c&csf=1&web=1&e=O6EKMz
   */
  handleAuthSync() {
    this.logInstance.log('handleAuthSync() called');

    // handle the explicit 'startCheckout' Piano callback
    // https://docs.piano.io/callbacks/#startevent
    window.tp = window.tp || [];
    window.tp.push( [ "addHandler", "startCheckout", this.createCheckoutViewByPianoCallback.bind(this)]);
    window.tp.push( [ "addHandler", "showOffer", this.handlePianoCallbackShowOffer.bind(this)]);

    this.handleExperienceLogic()
      .then(() => {
        /* Then, whenever new content is loaded without a browser refresh, you need to execute the Piano experience
         * you've designed in Composer by using this function:
         * @link https://docs.piano.io/track/implementing-piano
         */
        this.context.on(CONTEXT_KEY_EVENT_ACCOUNT_DATA_CHANGED, (event) => {
          this.logInstance.log('Accountdaten wurden geändert, call again handleExperienceLogic()');
          this.handleExperienceLogic();
        });
      });
  };

  /**
   * Wrapper function for Promise chaining 'provideJwt', 'restorePianoParams' and 'experienceExecute'
   *
   * @return Promise
   */
  handleExperienceLogic() {
    this.logInstance.log('handleExperienceLogic() called');

    return new Promise(async (resolve, reject) => {
      try {
        await this.provideJwt();
        resolve();
      } catch (e) {
        reject(e);
      }
    }).then(() => {
      this.updateCustomFieldAccessExpiration();
      this.restorePianoParams();
    }).catch((e) => {
      this.logInstance.log(e.message);
    }).finally(() => {
      this.experienceExecute();
    });
  }

  /**
   * This function checks if the user is logged in and Piano JWT is not empty.
   * If these conditions are met, the Piano JWT into window.tp JS object is provided.
   *
   * https://docs.piano.io/piano-id-auth-sync/#AuthSync
   * https://docs.piano.io/faq-article/how-to-create-a-jwt-token/
   *
   */
  async provideJwt() {
    this.logInstance.log('provideJwt() called');

    if (! await this.accountData.isLoggedIn()) {
      throw new Error('User is not logged in, stop function provideJwt()');
    }

    const pianoJwt = await this.accountData.getPianoJwt()
    if (pianoJwt === '') {
      throw new Error('accountData.getPianoJwt() is empty, stop function provideJwt()');
    }

    window.tp = window.tp || [];
    window.tp.push(["setExternalJWT", pianoJwt]);
    this.logInstance.log('window.tp.push(["setExternalJWT", "' + pianoJwt.slice(0, 3) + '...');
    this.context.trigger(CTXT_KEY_EVENT_PIANO_JWT_PUSHED);
  }

  /**
   * The function window.tp.experience.execute() must always be called, after providing the JWT but also without.
   */
  experienceExecute() {
    window.tp = window.tp || [];
    if (typeof window.tp.experience !== "object") {
      this.logInstance.warn('window.tp.experience is not a object, don\'t call execute()');
      return false;
    }

    if ( !this.context.values.has(CTXT_KEY_PIANO_INITIALIZED) )
    {
      this.logInstance.log('call window.tp.experience.init()');
      window.tp.experience.init();
      this.context.values.add(CTXT_KEY_PIANO_INITIALIZED, true);
    }

    this.logInstance.log('call window.tp.experience.execute()');
    window.tp.experience.execute();
  }

  /**
   * Function saves an order attempt in the '__pianoParams' cookie to restore it in case of a restart.
   * @todo tfenrich 41,6 Tage ist eine lange Zeit, sinnvoll ? (60 * 60 * 1000)
   * @todo tfenrich LocalStorage alternative implementieren
   * @param params object @link https://docs.piano.io/piano-id-auth-sync/?paragraphId=0a98b93b4c30ea1
   */
  savePianoParams (params) {
    this.logInstance.log('savePianoParams() called');
    document.cookie = COOKIE_NAME_PIANO_PARAMS + "=" + encodeURIComponent(JSON.stringify( params )) +
      "; expires=" + new Date(new Date().getTime() + 60 * 60 * 1000).toGMTString() +
      "; path=/; domain=." + (new Environment()).getDomain();
  }

  /**
   * Remove cookie '__pianoParams' with information about the order attempt
   */
  deletePianoParams () {
    this.logInstance.log('deletePianoParams() called');
    document.cookie = COOKIE_NAME_PIANO_PARAMS + "=; expires=-1; path=/; domain=." + (new Environment()).getDomain();
  }

  /**
   * There may already be a checkout attempt, then restore the order data from the cookie '__pianoParams' and
   * call startCheckout
   */
  restorePianoParams () {
    this.logInstance.log('restorePianoParams() called');

    window.tp = window.tp || [];
    if (typeof window.tp.util !== "object") {
      this.logInstance.warn('window.tp.util is not a object, don\'t call findCookieByName()');
      return;
    }

    const paramsCookie = window.tp.util.findCookieByName(COOKIE_NAME_PIANO_PARAMS);
    if ( typeof paramsCookie !== 'string' || paramsCookie === '' ) {
      this.logInstance.log('Cookie \'' + COOKIE_NAME_PIANO_PARAMS + '\' not found or empty, stop rendering restorePianoParams()');
      return;
    }

    try {
      // Try to parse stored JSON data
      const params = JSON.parse(paramsCookie);
      this.startCheckout(params);
    } catch (e) {
      this.logInstance.log('Value of cookie \'' + COOKIE_NAME_PIANO_PARAMS + '\'  isn\'t valid JSON data, stop rendering restorePianoParams()');
    }
  }

  /**
   *
   * @param params e.g. {"parentOuterHeight":"1175","logType":"offerShow","browserId":"m4b97iq0i0ylptuw","userProvider":"piano_id_lite","widget":"offer","pianoIdUrl":"https://sandbox.piano.io/id/","templateId":"OT5P4XLHF5KB","parentDualScreenLeft":"-1920","experienceActionId":"showOfferGWSLXRY712R86","customVariables":"{}","experienceId":"EXVRVBF1UHIF","containerSelector":"#paywall-test, #piano-static-paywall","offerType":"purchase","hasLoginRequiredCallback":"true","userState":"registered","showCloseButton":"false","displayMode":"inline","url":"https://xmedias3.mannheimer-morgen.de/index.php?artikel=-tobitestkategorie-tobias-test-artikel-&arid=1682473&puid=3&pageid=2490","initMode":"context","parentDualScreenTop":"25","parentWidth":"1920","userToken":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJyZWRGQUNUIiwiaXNzIjoiaHR0cHM6Ly93d3cubWFubmhlaW1lci1tb3JnZW4uZGUiLCJlbWFpbCI6InQuZmVucmljaEBoYWFzLW1lZGllbmdydXBwZS5kZSIsInN1YiI6IjIwMTU4NSIsImZpcnN0X25hbWUiOiJUb2JpYXMiLCJsYXN0X25hbWUiOiJGZW5yaWNoIiwianRpIjoiNzNiY2ExZmNjNWQ0NjM2ZTJkOGZiZTYxODE1ZTQ1Njg2MjVjOGMxY2U4NmRkY2I3Njc1NmVhZWJhODc2ZjZlNSIsInNhbHV0YXRpb24iOiJbXCJEaXZlcnNcIl0iLCJzdWJzY3JpcHRpb25MZXZlbCI6IltcImludGVybmFsVHJhZmZpY1wiXSJ9.4chKkyMs_QPBSu84hAJRmE1Blb3Wv5QoE7Wu9yhWi8c","requestUserAuthForLinkedTerm":"true","checkoutFlowId":"CF732C8H5091","parentHeight":"658","iframeId":"offer-0-4GQcJ","hideCompletedFields":"true","_qh":"03b4156fba","width":null,"offerId":"OF2QX3GC9T4N","formNameByTermId":"{}","customCookies":"{}","aid":"OwRtGt54su","initTime":"6273.60000000149","reactivateSubscriptionId":"","upgradeSubscriptionId":"","termId":"TMH3ZI50HC8P","affiliateState":{"issuerId":"https://www.piano.io","premium":"false","redemptionCandidateItemId":null,"creditStates":[],"userId":"201585","targetGroups":{"l1":"target","l2":null}},"type":"payment","closeOnLogout":true}
   */
  startCheckout (params) {
    this.logInstance.log('startCheckout() called');

    window.tp = window.tp || [];
    if (typeof window.tp.offer !== "object") {
      this.logInstance.warn('window.tp.offer is not a object, don\'t call startCheckout()');
      return;
    }

    // If params object is valid - start checkout
    if (params)
    {
      this.logInstance.log('call window.tp.offer.startCheckout(params); params =', params);
      window.tp.offer.startCheckout(params);

      this.deletePianoParams();
    }
  }

  async updateCustomFieldAccessExpiration() {

    if (!this.isAccessCheckNeeded()) {
      this.logInstance.log("Kein AccessCheck notwendig")
      return;
    }

    let params = { rid: this.pianoConfigObj.plusResourceId };
    this.logInstance.log("/access/check params: ", params);

    window.tp.api.callApi("/access/check", params, async (response) => {
      this.logInstance.log(JSON.stringify(response));

      if (!response.access?.granted) {
        this.logInstance.log(
          response.access
            ? `/access/check no access granted for given resource ${params.rid}`
            : "/access/check no access object in API answer"
        );
        return;
      }

      this.logInstance.log("/access/check Nutzer hat Zugriff");
      let expDate = response.access.expire_date;
      let curDate = Date.now() / 1000;
      let daysLeft = Math.floor((expDate - curDate) / 86400) + 1;

      this.logInstance.log('/access/check - der Zugriff wird in ' + daysLeft + ' Tagen ablaufen.');

      this.sendCustomFieldUpdate(response.access.user.uid, daysLeft);
    });
  }

  isAccessCheckNeeded() {
    if (this.skipAccessCheck) {
      this.logInstance.log("needsAccessCheck FALSE - new registration, can't have access yet.");
      return false;
    }

    let lastAccessCheck = localStorage.getItem(LOCAL_STORAGE_KEY_LAST_ACCESS_CHECK);

    // check access if localStorage == null
    if (!lastAccessCheck) {
      this.logInstance.log("needsAccessCheck TRUE - no LocalStorage entry for 'last_piano_access_check'")
      return true;
    }

    // check whether last access check older than 12 hours
    let timeElapsed = Date.now() - Number(lastAccessCheck);
    let isCheckNeeded = timeElapsed > TWELVE_HOURS_IN_MS;

    this.logInstance.log("last access check > 12 h? - " + isCheckNeeded);

    return isCheckNeeded;
  }

  // update Custom Fields via API
  sendCustomFieldUpdate(userId, daysLeft) {

    let formData = {
      'uid': userId,
      'form_name': 'x_days_sub_end',
      'custom_field_values': [{
        "field_name": "sub_expires_xdays",
        "value": daysLeft
      }]
    };

    this.logInstance.log(`send update for custom field days_till_access_ends: ${daysLeft} Tage`);

    let environment = this.pianoConfigObj.apiEnvironment;
    let aid = this.pianoConfigObj.pianoAppId;

    fetch(`https://${environment}.piano.io/id/api/v1/identity/userinfo?aid=${aid}&access_token=${window.tp.pianoIdLite.getToken()}&lang=de_DE`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(formData)
    }).then(() => {
      localStorage.setItem(LOCAL_STORAGE_KEY_LAST_ACCESS_CHECK, JSON.stringify(Date.now()))
      this.logInstance.log('/access/check - custom field "days_till_access_ends" updated');
    }).catch ((error) => {
      this.logInstance.log(`Error while updating Custom Fields: ${error.message}`);
    });
  }

  /**
   * @typedef {Object} PianoConfig
   * @property {string} espConfigId
   * @property {string} endpoint
   * @property {string} staticDomain
   * @property {string} pianoIdUrl
   * @property {string} pianoAppId
   * @property {string} apiEnvironment
   * @property {string} composerHost
   * @property {string} cxenseSiteId
   * @property {string} plusResourceId
   */

  /**
   * @param {string} domain - domain without subdomain, e.g. mannheimer-morgen.de
   * @returns {PianoConfig}
   */
  getPianoConfigObj(domain = this.env.getHostnameWithoutSubdomain()) {
    let isTestDatabaseEnv = this.env.isTestDatabaseEnvironment();

    const apiEnvironment = isTestDatabaseEnv ? 'sandbox' : 'api-eu';
    const composerHost = (isTestDatabaseEnv ? 'c2-test.' : 'c2.') + domain;
    const pianoIdUrl = (isTestDatabaseEnv ? 'id-auth-test.' : 'id-auth.') + domain;
    const endpoint = (isTestDatabaseEnv ? 'vx-test.' : 'vx.') + domain;
    const staticDomain = (isTestDatabaseEnv ? 'cdn-test.' : 'cdn.') + domain;

    const pianoConfigMap = {
      'mannheimer-morgen.de': {
        testPianoAppId: 'OwRtGt54su',
        prodPianoAppId: 'xgTkoeZmpe',
        testEspConfigId: '1112',
        prodEspConfigId: '489',
        cxenseSiteId: '5858483462373954406',
        plusResourceId: PIANO_MM_PLUS_RESOURCE_ID
      },
      'fnweb.de': {
        testPianoAppId: '9rE23QjNsu',
        prodPianoAppId: 'zmIxlKxmpe',
        testEspConfigId: '1114',
        prodEspConfigId: '488',
        cxenseSiteId: '5859609361043745026',
        plusResourceId: PIANO_FN_PLUS_RESOURCE_ID
      },
      'schwetzinger-zeitung.de': {
        testPianoAppId: 'RGQ5nOc1su',
        prodPianoAppId: 'b3rFSwT6pe',
        testEspConfigId: '1115',
        prodEspConfigId: '486',
        cxenseSiteId: '5856231664681315158',
        plusResourceId: PIANO_SZ_PLUS_RESOURCE_ID
      },
      'bergstraesser-anzeiger.de': {
        testPianoAppId: '3FLDo8oSsu',
        prodPianoAppId: 'JcAWYTTape',
        testEspConfigId: '1113',
        prodEspConfigId: '487',
        cxenseSiteId: '5860735267104250652',
        plusResourceId: PIANO_BA_PLUS_RESOURCE_ID
      }
    };

    const config = pianoConfigMap[domain];

    const pianoAppId = config ? (isTestDatabaseEnv ? config.testPianoAppId : config.prodPianoAppId) : '';
    const espConfigId = config ? (isTestDatabaseEnv ? config.testEspConfigId : config.prodEspConfigId) : '';
    const plusResourceId = config ? config.plusResourceId : '';
    const cxenseSiteId = config ? config.cxenseSiteId : '';

    const pianoConfigObj = {
      pianoAppId,
      espConfigId,
      composerHost,
      pianoIdUrl,
      endpoint,
      staticDomain,
      plusResourceId,
      cxenseSiteId,
      apiEnvironment,
    };

    this.logInstance.log('created pianoConfigObject');
    this.logInstance.log('pianoConfigObject:', pianoConfigObj);

    return pianoConfigObj;
  }

  /**
   * @param params - Piano checkout params e.g. {"parentOuterHeight":"1175","logType":"offerShow","isCheckout":"true","browserId":"m4h6ceea1wsbok1t","userProvider":"piano_id_lite","widget":"offer","pianoIdUrl":"https://sandbox.piano.io/id/","templateId":"OT5P4XLHF5KB","parentDualScreenLeft":"-1920","experienceActionId":"showOfferGWSLXRY712R86","customVariables":"{}","experienceId":"EXVRVBF1UHIF","containerSelector":"#paywall-test, #piano-static-paywall","termId":"TMH3ZI50HC8P","offerType":"purchase","hasLoginRequiredCallback":"true","gaAccount":"disabled","userState":"anon","reloadAfterLogin":"true","showCloseButton":"true","displayMode":"modal","url":"https://xmedias3.mannheimer-morgen.de/index.php?artikel=-tobitestkategorie-tobias-test-artikel-&arid=1682473&puid=3&pageid=2490","initMode":"context","parentDualScreenTop":"25","parentWidth":"1920","userToken":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJyZWRGQUNUIiwiaXNzIjoiaHR0cHM6Ly93d3cubWFubmhlaW1lci1tb3JnZW4uZGUiLCJlbWFpbCI6InQuZmVucmljaEBoYWFzLW1lZGllbmdydXBwZS5kZSIsInN1YiI6IjIwMTU4NSIsImZpcnN0X25hbWUiOiJUb2JpYXMiLCJsYXN0X25hbWUiOiJGZW5yaWNoIiwianRpIjoiNzNiY2ExZmNjNWQ0NjM2ZTJkOGZiZTYxODE1ZTQ1Njg2MjVjOGMxY2U4NmRkY2I3Njc1NmVhZWJhODc2ZjZlNSIsInNhbHV0YXRpb24iOiJbXCJEaXZlcnNcIl0iLCJzdWJzY3JpcHRpb25MZXZlbCI6IltcImludGVybmFsVHJhZmZpY1wiXSJ9.4chKkyMs_QPBSu84hAJRmE1Blb3Wv5QoE7Wu9yhWi8c","requestUserAuthForLinkedTerm":"true","checkoutFlowId":"CF732C8H5091","parentHeight":"732","iframeId":"offer-1-Od325","hideCompletedFields":"true","_qh":"01e2d16971","width":null,"offerId":"OF2QX3GC9T4N","formNameByTermId":"{}","customCookies":"{}","aid":"OwRtGt54su","initTime":"32373.800000000745","reactivateSubscriptionId":"","upgradeSubscriptionId":"","affiliateState":{"issuerId":"https://www.piano.io","premium":"false","redemptionCandidateItemId":null,"creditStates":[],"userId":"anon","targetGroups":{"l1":"target","l2":null}},"type":"payment"}
   */
  createCheckoutViewByPianoCallback(params) {
    this.logInstance.log('createCheckoutViewByPianoCallback() called');
    this.logInstance.log('checkout params.containerSelector => ', params.containerSelector);

    if ( typeof params.containerSelector !== 'string'
      || params.containerSelector === '' ) {
      this.logInstance.log('Checkout params attr \'containerSelector\' is empty');
      return this;
    }

    const checkoutEl = document.querySelector(params.containerSelector);
    if ( checkoutEl === null ) {
      this.logInstance.log('No element found with checkout params \'containerSelector\' ' + params.containerSelector);
      return this;
    }

    // create piano checkout view
    const checkoutView = new Checkout({ context: this.context, el: checkoutEl });
    checkoutView.render(params);
  }

  handlePianoCallbackShowOffer (offerParams) {
    //this.logInstance.log('Piano callback \'showOffer\' triggered, arg', offerParams);
    const pattern = SELECTOR_PAYWALL;
    if ( offerParams.containerSelector.match(pattern) !== null ) {
      this.logInstance.log('Piano callback \'showOffer\' triggered for paywall, offerParams.containerSelector match '
        + pattern + ', context.values.add(' + CTXT_KEY_PIANO_PAYWALL_OFFER_SHOWN + ', true) ');
      this.context.values.add(CTXT_KEY_PIANO_PAYWALL_OFFER_SHOWN, true);
    }
  }
}
